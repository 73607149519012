<template>
    <div>
        <img :src="img" id="dice">
        <h3 class="mt-2 state">State: <span class="st">{{textState}}</span></h3>
        <small v-show="timeElapsed !== ''">{{timeElapsed}}</small>
    </div>
</template>

<script>
    import moment from "moment"

    export default {
        name: "Dice",
        props: {
            result: Number,
            state: Number, //0 - Idle, 1 - Start Spin, 2 - Continue Spin, 3 - Show Result
        },
        data() {
            return {
                img: "",
                interval: null,
                startTime: -1,
                timeElapsed: "",
            }
        },
        computed: {
            textState() {
                switch (this.state) {
                    case 0:
                        return "Idle";
                    case 1:
                    case 2:
                        return "Spinning";
                    case 3:
                        return "Result";
                    default:
                        return "Unkown"
                }
            },
        },
        watch: {
            result: function () {
                console.log("Result in", this.result);
                this.onResult(this.result)
            },

            state: function () {
                console.log("state change", this.state);
                switch (this.state) {
                    case 0:
                        this.onIdle();
                        break;
                    case 1:
                        this.onStart();
                        break;
                    case 2:
                        this.onContinueSpin();
                        break;
                }
            }
        },

        methods: {
            onStart: function () {
                this.startTime = new Date().getTime();
                this.img = "/lounge/dice/dice_start_spin.gif";
                setTimeout(() => {
                    console.log("Checking continue spin", this.state);
                    if (this.state == 1)
                        this.onContinueSpin();
                }, 2000);

                this.timeElapsed = moment(this.startTime).fromNow();
                this.interval = setInterval(() => {
                    this.timeElapsed = moment(this.startTime).fromNow();
                }, 1000)
            },

            onIdle: function () {
                this.img = "/lounge/dice/dice_idle.gif";
                this.timeElapsed = "";
                clearInterval(this.interval);
            },

            onContinueSpin: function () {
                console.log("onContinueSpin");
                this.img = "/lounge/dice/dice_only_spin.gif";
            },

            onResult: function () {
                this.img = `/lounge/dice/dice_result_${this.result}.gif`;
                clearInterval(this.interval);
            }
        },
        beforeMount() {
            this.onIdle();
            moment.relativeTimeThreshold("ss", 3);  // setter
        }
    }
</script>

<style scoped>
    #dice {
        width: 200px;
    }

    .state .st {
        text-transform: uppercase;
        color: #26b6d4;
    }

    h3 {
        margin-bottom: 0;
    }
</style>